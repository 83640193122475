<template>
  <GeneralDialogTemplate no-padding>
    <template slot="header">
      <span class="font-weight-medium">Konto seaded</span>
    </template>
    <v-row
      no-gutters
      class="ma-5 container-width"
    >
      <v-col cols="12">
        Konto id: <span class="font-weight-bold">{{ user.uid }}</span>
      </v-col>
      <v-col cols="12">
        Konto email: <span class="font-weight-bold">{{ user.email }}</span>
      </v-col>
      <v-col cols="12">
        Konto telefon: <span class="font-weight-bold">{{ user.phoneNumber }}</span>
      </v-col>
    </v-row>
    <template slot="footer">
      <v-row
        no-gutters
        justify="end"
      >
        <!-- <v-btn color="primary" text>Loobu</v-btn> -->
        <!-- <v-btn color="primary">Salvesta</v-btn> -->
      </v-row>
    </template>
  </GeneralDialogTemplate>
</template>
<script>
import GeneralDialogTemplate from '../../templates/GeneralDialogTemplate.vue';
import authModule from '../../../store/modules/auth';

export default {
  components: {
    GeneralDialogTemplate,
  },
  setup() {
    const { user } = authModule();
    return {
      user,
    };
  },
};
</script>
<style lang="scss" scoped>
.container-width{
    width: 500px
}

</style>
